<template>
  <div>
    <el-table class="table" :data="userListData" stripe border>
      <el-table-column
        type="index"
        label="序号"
        width="120"
        fixed
        align="center"
        :resizable="false"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="name"
        align="center"
        fixed
        label="姓名"
        width="120"
      ></el-table-column>
      <el-table-column prop="phone" align="center" label="联系方式" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="orgName" align="center" label="公司名称" show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="email"
        align="center"
        label="邮箱"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="content"
        align="center"
        label="内容"
        show-overflow-tooltip
        fixed="right"
      ></el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; float: right"
      :total="total"
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 30, 50]"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>

<script>
import { getPlatformPage } from '@/api';
export default {
  data() {
    return {
      total: 0,
      queryForm: {
        pageSize: 10,
        pageNo: 1,
        search: ''
      },
      userListData: []
    };
  },
  methods: {
    getPlatformPage() {
      getPlatformPage(this.queryForm).then(res => {
        if (res.data.code === 200) {
          const { total, records } = res.data.data;
          this.userListData = records;
          this.total = total;
        }
      });
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getPlatformPage();
    },
    handleSizeChange(val) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = val;
      this.getPlatformPage();
    }
  },
  created() {
    this.getPlatformPage();
  }
};
</script>
<style>
.el-tooltip__popper {
  max-width: 30%;
  padding-bottom: 5px !important;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
